const tileIdToImage = {
  "0": 'Grass',
};

export const Tile = ({
  x,
  y,
  id
}) => {
  return (
    <div
      style={{
        position: 'absolute',
        left: x,
        top: y,
        width: 100,
        height: 100,
        backgroundImage: `url(/images/tiles/${tileIdToImage[id.toString()]}.png)`,
        backgroundSize: 'cover',
      }}
    />
  );
};
