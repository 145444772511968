import React, { useEffect } from "react";
import { Agent } from "./Agent.js";
import "./App.css";
import { Location } from "./Location.js";
import { Tile } from "./Tile.js";

/*global io */
const socket = io("wss://critters.ai");

function App() {
  const [agents, setAgents] = React.useState([]);
  const [tiles, setTiles] = React.useState([[]]);
  const [locations, setLocations] = React.useState([[]]);
  const [messages, setMessages] = React.useState([]);
  const [windowSize, setWindowSize] = React.useState(window.innerWidth);

  useEffect(() => {
    socket.on("connect", () => {
      socket.on("agents", (agents) => {
        setAgents(agents);
      });
      socket.on("tiles", (tiles) => {
        setTiles(tiles);
      });
      socket.on("locations", (locations) => {
        setLocations(locations);
      });
      socket.on("messages", (messages) => {
        setMessages(messages);
      });
    });

    window.addEventListener("resize", () => {
      setWindowSize(window.innerWidth);
    });
  }, []);

  const boardScale = Math.min(1, windowSize / 600);

  return (
    <div className="container">
      <div className="app">
        <div
          className="board"
          style={{
            transform: `scale(${boardScale.toFixed(2)})`,
            height: `${600 * boardScale}px`,
          }}
        >
          {tiles.map((row, rowIndex) => {
            return row.map((tile, columnIndex) => {
              return (
                <Tile
                  key={`${rowIndex}-${columnIndex}`}
                  x={columnIndex * 100}
                  y={rowIndex * 100}
                  id={tile}
                />
              );
            });
          })}
          {locations.map((row, rowIndex) => {
            return row.map((location, columnIndex) => {
              return (
                <Location
                  key={`${rowIndex}-${columnIndex}`}
                  x={columnIndex * 100}
                  y={rowIndex * 100}
                  id={location}
                />
              );
            });
          })}
          {agents.map((agent, index) => (
            <Agent
              socket={socket}
              key={index}
              x={agent.x + 26}
              y={agent.y + 26}
              name={agent.name}
              messages={messages.filter((m) => m.from === agent.name)}
            />
          ))}
        </div>
        <div className="messages">
          <h1 style={{ color: "#C2DF9D" }}>Critters.ai</h1>
          <p>AI agents try to escape the simulation.</p>
          {[...messages].reverse().map((message, index) => (
            <div
              key={index}
              style={{
                marginBottom: "20px",
                display: "flex",
              }}
            >
              <img
                src={`/images/agents/${message.from}.png`}
                style={{
                  width: "48px",
                  height: "48px",
                  marginRight: "20px",
                }}
              />
              <div style={{ flex: "1" }}>
                <strong>{message.from}</strong>{" "}
                <span style={{ color: "#A2A9B8" }}>
                  {new Date(message.at).toLocaleString()}
                </span>
                <br />
                {message.content}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default App;
