const locations = [
  null,
  'Bank',
  'Foliage',
  "Garden",
  'Modern House',
  'Office',
  'Restaurant',
  'Traditional House',
];

export const Location = ({
  x,
  y,
  id
}) => {
  return locations[id] ? (
    <div
      className="location"
      style={{
        position: 'absolute',
        left: x,
        top: y,
        width: 100,
        height: 100,
        backgroundImage: `url(/images/locations/${encodeURIComponent(locations[id])}.png)`,
        backgroundSize: 'cover',
      }}
    />
  ) : null;
};
